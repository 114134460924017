<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_197_16817)">
        <path d="M2.49951 5.85905C2.49951 5.41702 2.67511 4.9931 2.98767 4.68054C3.30023 4.36798 3.72415 4.19238 4.16618 4.19238H15.8328C16.2749 4.19238 16.6988 4.36798 17.0114 4.68054C17.3239 4.9931 17.4995 5.41702 17.4995 5.85905C17.4995 6.30108 17.3239 6.725 17.0114 7.03756C16.6988 7.35012 16.2749 7.52572 15.8328 7.52572H4.16618C3.72415 7.52572 3.30023 7.35012 2.98767 7.03756C2.67511 6.725 2.49951 6.30108 2.49951 5.85905Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.16602 7.52637V15.8597C4.16602 16.3017 4.34161 16.7257 4.65417 17.0382C4.96673 17.3508 5.39065 17.5264 5.83268 17.5264H14.166C14.608 17.5264 15.032 17.3508 15.3445 17.0382C15.6571 16.7257 15.8327 16.3017 15.8327 15.8597V7.52637" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.33301 10.8594H11.6663" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_197_16817">
        <rect width="20" height="20" fill="currentColor" transform="translate(-0.000488281 0.859375)"/>
        </clipPath>
        </defs>
    </svg>
</template>