<template>
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99854 2.21582V3.46551" stroke="#F87171" stroke-width="0.833125" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M6.99854 11.7959V13.0456" stroke="#F87171" stroke-width="0.833125" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M10.828 3.80176L9.94434 4.68539" stroke="#F87171" stroke-width="0.833125" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M4.05307 10.5762L3.16943 11.4598" stroke="#F87171" stroke-width="0.833125" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M12.4137 7.63086H11.1641" stroke="#F87171" stroke-width="0.833125" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M2.83318 7.63086H1.5835" stroke="#F87171" stroke-width="0.833125" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M10.828 11.4598L9.94434 10.5762" stroke="#F87171" stroke-width="0.833125" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M4.05307 4.68539L3.16943 3.80176" stroke="#F87171" stroke-width="0.833125" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M6.99834 9.71348C8.14865 9.71348 9.08115 8.78097 9.08115 7.63066C9.08115 6.48036 8.14865 5.54785 6.99834 5.54785C5.84803 5.54785 4.91553 6.48036 4.91553 7.63066C4.91553 8.78097 5.84803 9.71348 6.99834 9.71348Z" stroke="#F87171" stroke-width="0.833125" stroke-miterlimit="10" stroke-linecap="round"/>
    </svg>
</template>