<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_197_16814)">
        <path d="M3.33301 5.02572C3.33301 4.8047 3.42081 4.59274 3.57709 4.43646C3.73337 4.28018 3.94533 4.19238 4.16634 4.19238H7.49967C7.72069 4.19238 7.93265 4.28018 8.08893 4.43646C8.24521 4.59274 8.33301 4.8047 8.33301 5.02572V8.35905C8.33301 8.58006 8.24521 8.79202 8.08893 8.9483C7.93265 9.10459 7.72069 9.19238 7.49967 9.19238H4.16634C3.94533 9.19238 3.73337 9.10459 3.57709 8.9483C3.42081 8.79202 3.33301 8.58006 3.33301 8.35905V5.02572Z" stroke="currentColor" stroke-opacity="0.72" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.666 5.02572C11.666 4.8047 11.7538 4.59274 11.9101 4.43646C12.0664 4.28018 12.2783 4.19238 12.4993 4.19238H15.8327C16.0537 4.19238 16.2657 4.28018 16.4219 4.43646C16.5782 4.59274 16.666 4.8047 16.666 5.02572V8.35905C16.666 8.58006 16.5782 8.79202 16.4219 8.9483C16.2657 9.10459 16.0537 9.19238 15.8327 9.19238H12.4993C12.2783 9.19238 12.0664 9.10459 11.9101 8.9483C11.7538 8.79202 11.666 8.58006 11.666 8.35905V5.02572Z" stroke="currentColor" stroke-opacity="0.72" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.33301 13.3597C3.33301 13.1387 3.42081 12.9267 3.57709 12.7704C3.73337 12.6142 3.94533 12.5264 4.16634 12.5264H7.49967C7.72069 12.5264 7.93265 12.6142 8.08893 12.7704C8.24521 12.9267 8.33301 13.1387 8.33301 13.3597V16.693C8.33301 16.914 8.24521 17.126 8.08893 17.2823C7.93265 17.4386 7.72069 17.5264 7.49967 17.5264H4.16634C3.94533 17.5264 3.73337 17.4386 3.57709 17.2823C3.42081 17.126 3.33301 16.914 3.33301 16.693V13.3597Z" stroke="currentColor" stroke-opacity="0.72" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.666 15.0264H16.666M14.166 12.5264V17.5264" stroke="currentColor" stroke-opacity="0.72" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_197_16814">
        <rect width="20" height="20" fill="white" transform="translate(-0.000488281 0.859375)"/>
        </clipPath>
        </defs>
    </svg>
</template>