<template>


  <!--  ADMIN -->
  <li class="my-px" v-if="User.role == 'admin'" @click="$emit('onClick')">
    <router-link
        to="/dashboard/residanat"
        :class="[
              dark
                ? 'text-white hover:bg-gray-100 hover:text-gray-700'
                : ' text-gray-700 hover:bg-gray-200',
              dark & (active)
                ? 'bg-white text-gray-700'
                : '',
              !dark & (active) ? 'text-gray-700 bg-gray-200' : '',
            ]"
        class="flex flex-row items-center menu-item rounded-99px"
    >
            <span
                
                class="flex items-center justify-center text-lg"
            >
            <AnnalsIcon />
            </span>
      <span class="ml-3" :class="clicked ? 'hidden' : 'block'"
      >Annales Résidanat </span>
    </router-link>
  </li>


  <!--  END ADMIN -->


  <!--  USER -->

  <li class="my-px" v-else>
    <template v-if="is_residatant_subscriber">
      <button
          @click="openMenu = !openMenu"
          href="#"
          :class="
              openMenu ? 'open' : ''
            "
          class="
              w-full
              flex
              justify-between
              items-center
              menu-item
              rounded-99px
            "
            :style="clicked ? 'padding: 12px;border-radius: 50%' : ''"  
      >
        <div class="flex items-center">
              <span
                  
                  class="flex items-center justify-center text-lg"
              >
                <AnnalsIcon />
              </span>
          <span class="ml-3" :class="clicked ? 'hidden' : 'block'"
          >Annales Résidanat</span
          >
        </div>
        <div>
              <span :class="clicked ? 'hidden' : 'block'">
                <svg
                    class="h-4 w-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      v-show="!openMenu"
                      d="M9 5L16 12L9 19"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      style="display: none"
                  ></path>
                  <path
                      v-show="openMenu"
                      d="M19 9L12 16L5 9"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  ></path>
                </svg>
              </span>
        </div>
      </button>

      <div
          v-show="openMenu"
          :class="clicked ? 'hidden' : 'block'"
          class=" rounded-lg submenu-item-wrapper"
      >
        <div class="tree-submenu">
          <TreeIcon />
        </div>
        <button @click="openSelectResidanatExamModal"
                class="flex items-center submenu-item">
          <span>
            <AddIcon />
          </span>
          Nouvel examen
        </button>
        <router-link to="/dashboard/residanat/examensList"
                    class="flex items-center submenu-item">
          <span>
            <OldItems />
          </span>
          Anciens examens
        </router-link>
      </div>
    </template>
  </li>

</template>
<!--  END USER -->


<script>
import {Plus, MessageBox} from '@element-plus/icons';
import AnnalsIcon from '../../icons/AnnalsIcon.vue';
import TreeIcon from '../../icons/TreeIcon.vue';
import AddIcon from '../../icons/AddIcon.vue';
import OldItems from '../../icons/OldItems.vue';
export default {
  components: {
    MessageBox,
    Plus,
    AnnalsIcon,
    TreeIcon,
    AddIcon,
    OldItems
  },
  props: {
    dark: Boolean,
    active: Boolean,
    openSelectResidanatExamModal: Function
  },
  data() {
    return {
      openMenu: false,
    }
  },
  methods: {},
  computed: {
    User() {
      return this.$store.getters.get_user;
    },
    clicked() {
      return this.$store.getters.get_clicked;
    },
    is_residatant_subscriber() {
      return this.$store.getters.is_residatant_subscriber;
    },
  },
  watch: {
    clicked(newVal,oldVal){
      if(newVal == true) {
        if(this.openMenu) {
          this.openMenu = false;
        }
      }
    }
  }
}
</script>