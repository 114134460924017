<template>
   <div>
       <div
        v-show="settingPanel"
        @click="closeSettingsPanel"
        :class="dark ? 'bg-gray-200' : 'bg-red-200'"
        class="fixed inset-0 z-10"
        style="opacity: 0.5"
        aria-hidden="true"
       ></div>
    
    <!-- Panel -->
    <section
        tabindex="-1"
        v-show="settingPanel"
        @keydown.escape="closeSettingsPanel"
        :class="dark ? 'bg-gray-700 text-light' : 'bg-white'" 
        class="fixed inset-y-0 right-0 z-20 w-full max-w-x shadow-xl sm:max-w-md focus:outline-none"
        aria-labelledby="settinsPanelLabel"
    >
        <div class="absolute left-0 p-2 transform -translate-x-full">
        <!-- Close button -->
        <button
            @click="closeSettingsPanel"
            class="p-2 text-white rounded-md focus:outline-none focus:ring-current"
        >
            <svg
            class="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        </div>
        <!-- Panel content -->
        <div class="flex flex-col items-center h-screen">
        <!-- Panel header -->
        <div class="w-full sm:hidden block">
            <button
            @click="closeSettingsPanel"
            :class="dark ? 'text-white' : 'text-gray-700'"
            class="p-2  rounded-md focus:outline-none focus:ring-current"
            >
                <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
        <div
            :class="dark ? 'border-white text-white' : 'border-gray-500 text-gray-700'"  class="flex flex-col items-center justify-center flex-shrink-0 px-4 py-8 space-y-4 border-b"
        >
            <span class="flex items-center justify-center text-lg text-gray-700">
                <svg
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  :class="dark ? 'text-white' : 'text-gray-700'"
                  class="h-10 w-10"
                >
                  <path
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </span>
            <h2 id="settinsPanelLabel" 
                  :class="dark ? 'text-white' : 'text-gray-700'" class="text-xl font-medium ">Paramètres</h2>
        </div>
        <!-- Content -->
        <div class="flex-1 overflow-hidden hover:overflow-y-auto">
            <!-- Theme -->
            <div class="p-4 space-y-4 md:p-8">
            <h6 :class="dark ? 'text-white' : 'text-gray-700'" class="text-lg font-medium text-center">Thème</h6>
            <div class="flex items-center space-x-8">
                <!-- Light button -->
                <button
                @click="changeMode(isDark)"
                :class="dark ? 'text-white focus:outline-none focus:ring focus:ring-gray-300 dark:focus:ring-gray-800' : 'text-gray-700 focus:outline-none focus:ring focus:ring-red-300 dark:focus:ring-red-700'"
                class="flex items-center justify-center px-4 py-2 space-x-4 transition-colors border rounded-md hover:text-gray-900 hover:border-gray-900 dark:border-indigo-600 dark:hover:text-indigo-100 dark:hover:border-indigo-500 "
                >
                <span>
                    <svg
                    class="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    />
                    </svg>
                </span>
                <span>Clair</span>
                </button>

                <!-- Dark button -->
                <button
                @click="changeMode(!isDark)"
                :class="dark ? 'text-white' : 'text-gray-700'"
                class="flex items-center justify-center px-4 py-2 space-x-4 transition-colors border rounded-md hover:text-gray-900 hover:border-gray-900 dark:border-indigo-600 dark:hover:text-indigo-100 dark:hover:border-indigo-500 focus:outline-none focus:ring focus:ring-red-300 dark:focus:ring-red-700"
               
                >
                <span>
                    <svg
                    class="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                    />
                    </svg>
                </span>
                <span>Sombre</span>
                </button>
            </div>
            </div>
        </div>
        </div>
    </section>
   </div>
</template>

<script>
export default {
    data(){
        return{
            isDark: false
        }
    },

    props:{
        settingPanel: Boolean,
        dark : Boolean,
        changeMode:Function,
        closeSettingsPanel:Function
    },

    


    

}
</script>

<style>

</style>